#root > .wrapper > .sidebar > .logo {
	display: flex;
	background-color: var(--primary-color);
}
.sidebar-mini > #root > .wrapper > .sidebar > .logo {
	display: block;
}

.sidebar .logo .simple-text .logo-img {
	background-color: #fff !important;
}

.navbar.navbar-transparent,
.bg-white {
  background-color: var(--nav-bar-background) !important;
  &.splash{
    background-color: var(--nav-splash-background)!important;
}
}

.maintenance-banner {
	background-color: rgba( var(--banner-bk-RR) , var(--banner-bk-GG), var(--banner-bk-BB), var(--banner-bk-opacity));
	height: 35px;
	color: #000;
	text-align: center;
	padding-top: 5px;
	font-size: 18px;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9999;
  }

.sidebar:after,
.off-canvas-sidebar:after {
	background:red;
}
.btn,
.navbar .navbar-nav > a.btn {
	background-color: transparent !important;
}

.btn.btn-default:hover,
.btn.btn-default:focus,
.btn.btn-default:active,
.btn.btn-default:active:focus,
.navbar .navbar-nav > a.btn.btn-default:hover,
.navbar .navbar-nav > a.btn.btn-default:focus,
.navbar .navbar-nav > a.btn.btn-default:active,
.navbar .navbar-nav > a.btn.btn-default:active:focus {
	color: white !important;
}

button#minimizeSidebar {
	background: transparent !important;
}

.logo:after {
	display: none;
}

.main-panel {
	background-color: #F9F9FA !important;
}

.pro-icons {
	color: white !important;
}

.sidebar .nav li > a {
	text-transform: capitalize !important;
}

.sidebar-mini
	> #root
	.sidebar-wrapper
	li.active
	> a:not([data-toggle="collapse"]):after {
	width: 130% !important;
}

.move-left {
	margin-right: 15px !important;
	font-size: 18px !important;
}

.navbar .navbar-nav .nav-link {
	text-transform: capitalize !important;
}

.move-left-user {
	margin-left: -21px !important;
}

.navbar-nav > .nav-item:first-child {
	margin-right: -5px !important;
}

.move-right {
	margin-right: -7px !important;
	font-size: 18px !important;
}

.box {
	display: flex;
	align-items: center;
	justify-content: center;
}

.center-h-v {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.card {
	/* margin-top: 90px; */
	box-shadow: 0px 1px 4px #888, 0px 1px 1px #888;

	padding: 30px;
}

.large_purple {
	font-size: 30px;
	color: var(--primary-color);
}


.large_green {
	font-size: 30px;
	color: #a6ce39;
}

.center_more {
	margin-left: 70px;
	margin-right: 70px;
}

.button,
.btn-primary {
	background-color: var(--primary-color) !important;
	border: none;
	color: white;
	padding: 10px 40px 10px 40px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 4px 2px;
	cursor: pointer;
	margin-top: 10px;
	margin-bottom: 20px;
	box-shadow: 0px 1px 4px #888, 0px 1px 1px #888 !important;
}

.btn-primary-force {
	background-color: #3a149e !important;
	border: none !important;
	color: white !important;
	padding: 10px 40px 10px 40px !important;
	text-align: center !important;
	text-decoration: none !important;
	display: inline-block !important;
	font-size: 16px !important;
	margin: 4px 2px !important;
	cursor: pointer !important;
	margin-top: 10px !important;
	margin-bottom: 20px !important;
	box-shadow: 0px 1px 4px #888, 0px 1px 1px #888 !important;
	border-radius: 8px !important;
}

.btn-primary-force:hover{
	background-color: #5140e9 !important;
	box-shadow: 2px 3px 16px #5140e9, -1px 0px 11px #5140e9 !important;
}

.button:hover,
.btn-primary:hover {
	background-color: var(--secondary-color) !important;
	box-shadow: 2px 3px 16px var(--secondary-color-shadow), -1px 0px 11px var(--secondary-color-shadow) !important;
}

.white_style {
	color: #fff;
}

.nav_icon_resize {
	font-size: 18px !important;
	margin-right: 5px;
}

.blue_style {
	border-radius: 8px !important;
}

.blue_style.splash_button {
	width: 282px !important;
	height: 43px !important;
	border-radius: 7px !important;
}

.button.disabled.noClick{
	pointer-events: none;	
}

.button.disabled {
	opacity: 0.5;
}

.btn-danger {
	background-color: red !important;
	color: white !important;
}

.btn-danger:hover {
	background-color: darkred !important;
	color: white !important;
}

.xl-icon,
.pro-icons {
	font-size: 34px !important;
	color: white !important;
}

.card-body {
	text-align: center;
}

.card-title {
	font-size: 20px;
	color: var(--primary-color);
	font-weight: bold;
}

.flex-right {
	display: flex;
}

.flex-right > .card {
	margin-right: 20px !important;
	margin-top: 30px !important;
	min-height: 300px;
	max-height: 350px;
}

.bg-holder-bt {
	box-shadow: 0px 6px 8px rgb(234, 234, 234), 0px 1px 1px #888 !important;
	border: 1px solid lightgray;
	background-color: rgb(240, 240, 240);
	padding: 10px;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.closing-bg-name > p {
	background-color: lavender;
	padding: 5px;
}

.closing_table {
	width: 100%;
}
.closing_table > tbody > tr > td:first-child {
	float: left;
	color: #b0b5bd;
}
.closing_table > tbody > tr > td + td {
	float: right;
}

.navbar a.dropdown-item {
	color: var(--primary-color) !important;
}
.navbar a.dropdown-item:hover {
	color: darkgray !important;
}

.content > .loan-holder {
	display: flex;
	flex-direction: column;
}

.clientInfo {
	/* background-color: red; */
	padding: 15px;
}

.loanData {
	/* background-color: blue; */
	display: flex;
	gap: 28px;
}

.loanDataHolder {
	/* flex-grow: 1;
	flex-shrink: 1; */
	width: 500px;
	text-align: center;

	/* border: 1px solid black; */
}

.loanFirstBox {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.loanSecondBox {
	display: flex;
	flex-direction: column;
}

.funded {
	width: 100% !important;
}

.skinnyBox {
	padding: 0px !important;
	width: 80%;
	overflow: hidden;
	margin-bottom: 25px;
	height: 105px;
}

.skinnyBox:last-child {
	margin-bottom: 20px;
}

.lineCredit {
  border-bottom: lightgray 1px solid;
	padding-bottom: 20px;
	margin-bottom: 20px;
}
.lineCredit,
.drawAmount {
	text-align: left;
}

.skinnyBox > .card-body > p,
.rateMonthly > .card > p,
.lineCredit-holder > .lineCredit > p,
.lineCredit-holder > .drawAmount > p {
	color: darkgray;
	margin-bottom: 0px !important;
}

.skinnyBox > .card-body > div + p {
	margin-top: 8px;
}

.rateMonthly > .card > div + p {
	margin-top: 48px;
}

.skinnyBox > .card-body {
	text-align: left;
	margin-bottom: 20px;
	position: relative;
}

.skinnyBox > .card-body > p.xl_,
.lineCredit-holder > .drawAmount > p.xl_ {
	font-size: 30px;
	color: var(--primary-color);
	font-weight: bold;
	margin-bottom: 0px;
}

.skinnyBox > .card-body > p.xl_,
.lineCredit-holder > .drawAmount > p.drawAmountText {
  font-size: 30px;
	color: var(--primary-color);
	font-weight: bold;
	margin-bottom: 0px;
}

.skinnyBox > .card-body > p.xl_,
.lineCredit-holder > .drawAmount > p.xl_draw {
	font-size: 30px;
	color: var(--primary-color);
	font-weight: bold;
	margin-bottom: 0px;
}

.rateMonthly > .card > p.xl__,
.lineCredit-holder > .lineCredit > p.xl__ {
	font-size: 30px;
	color: var(--primary-color);
	font-weight: bold;
	margin-bottom: 0px;
}

.rateMonthly > .card > p.xl__,
.lineCredit-holder > .lineCredit > p.xl__lineCredit {
	font-size: 30px;
	color: var(--primary-color);
	font-weight: bold;
	margin-bottom: 0px;
}

.skinnyBox > .card-body > div {
	position: relative;
}
.skinnyBox > .card-body > div > i {
	font-size: 170px;
	overflow: hidden;
	position: absolute;
	top: -11px;
	left: 72%;
	color: #f5f5f5;
}

.skinnyBox > .card-body > div > .stamp-icon {
	font-size: 110px;
	transform: rotate(20deg);
	left: 80% !important;
}

.clientInfo > p {
	font-size: 22px;
	color: $primary-color;
	font-weight: bold;
}

.font-no-bold {
	font-weight: normal;
	color: black;
}

.size_M {
	display: flex;
	flex-direction: row;
	margin-bottom: 25px;
}

.rateMonthly {
	display: flex;
	/* justify-content: space-evenly; */
	gap: 28px;
}

.rate,
.monthly {
	/* flex: 1 1 0px; */
	/* margin-right: 65px; */
	width: 250px;
	height: 234px;
	overflow: hidden;
	position: relative;
}

.rateMonthly > .card > div > i {
	font-size: 170px;
	overflow: hidden;
	position: absolute;
	top: 156px;
	left: 13%;
	color: #f5f5f5;
}

.chart > .card-body {
	padding: 0px !important;
}
.chart > i {
	position: absolute;
	top: 38px;
	font-size: 89px;
	overflow: hidden;
	left: 189px;
	color: #f5f5f5;
	z-index: 9999999;
}

.chart {
	position: relative;
	left: -94px;
	/* background-color: red; */
}

.setSizeW {
	margin-top: 129px !important;
	width: 40%;
	max-width: 40%;
	min-width: 40%;
}

div.preClosingParent {
	display: flex;
	margin-top: 125px !important;
	flex-wrap: wrap;
}
p.preClosingText {
	font-size: 12px;
	color: #888;
	margin-top: 5px;
	margin-bottom: 0px;
}

.navbarInfoText {
	padding: 10px 20px 10px 20px;
	font-size: 11px !important;
}

#ProgressBar {
	width: 100%;
	height: 13px;
	padding-left: 0px;
	padding-top: 4px;
	padding-right: 4px;
	padding-bottom: 4px;
}

#Progress {
	width: 100%;
	background-color: #4bc31b;
	height: 100%;
	border-color: #3eae16;
	animation-name: progressBar;
	animation-iteration-count: 1;
	animation-duration: 2s;
}

@keyframes progressBar {
	0% {
		width: 10%;
	}

	100% {
		width: 100%;
	}
}

.parent-progress-bar-holder {
	display: flex;
	flex-wrap: nowrap;
	margin-top: 5px;
}
.parent-progress-bar-holder-child-one {
	text-align: left;
	padding: 0 20px;
	width: 85%;
}

.preClosingButton {
	z-index: 99999;
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.pre-closingTask {
	margin-top: -15px;
	width: 35px;
	height: 35px;
	padding: 6px 0;
	text-align: center;
	border-radius: 35px;
	margin-bottom: 0px !important;
	-webkit-border-radius: 35px;
	-moz-border-radius: 35px;
	-ms-border-radius: 35px;
	-o-border-radius: 35px;
}

.preClosingError {
	font-size: 34px;
	margin-top: -18px;
}
.dragDropContent {
	margin-top: 20px;
}
p.warning-msg {
	color: red !important;
}
.setSizeM {
	max-height: none !important;
	width: 40%;
	max-width: 40%;
	min-width: 40%;
}

.setSizeM:nth-child(-n + 2) {
	margin-top: 125px !important;
}
div.setSizeL.card {
	margin-right: 20px !important;
	margin-bottom: 20px !important;
}
.setWidthDescription {
	word-wrap: break-word;
	text-align: center;
}

.setSizeL {
	width: 500px;
	max-width: 500px;
	min-width: 40%;
	height: fit-content;
}

.card-schedule-content {
	margin-left: 10px;
	margin-right: 10px;
	max-width: 350px;
}

.borrower-info-text {
	display: block;
	padding: 5px 0 10px;
	background-color: #f9f9fa;
	border-radius: 12px;
}

.schedule-button {
	border-radius: 10px;
	padding: 15px;
	margin-top: 30%;
}

.shedule-button-margin {
	margin-top: 20%;
}

.iframe-modal {
	max-width: 90%;
	margin: -35px auto auto auto;
	width: 100%;
}

.notification-modal {
	max-width: 600px;
	margin: 0px auto auto auto;
	width: 100%;
}

.exit-container {
	position: relative;
	width: 100%;
}

.iframe-exit-icon {
	position: absolute;
	right: 30px;
	top: 15px;
	color: var(--primary-color);
	font-size: 30px;
	cursor: pointer;
}

.schedule-iframe {
	display: flex;
	max-height: 600px;
	min-height: 600px;
	height: 100%;
}

/* display frame */

.display-iframe {
	top: 100px;
	left: 0;
	bottom: 0;
  right: 0;
  position: absolute;
	width: 100%;
	height: 100%;
	border: none;
}

/* spinner  */

.pos-center {
	position: fixed;
	top: calc(50% - 40px);
	left: calc(50% - 40px);
}

.loader {
	margin: auto;
	border: 10px solid #f3f3f3;
	border-top: 10px solid var(--primary-color);
	border-radius: 50%;
	width: 80px;
	height: 80px;
	animation: spin 1s linear infinite;
}

.pending-spinner {
	height: 3rem;
    width: 3rem;
    color: var(--primary-color);
}

.spinner-container {
	margin-bottom: -12px;
}

.return-link {
	color: var(--primary-color);
	margin-top: 20px !important;
	text-decoration: underline;
	font-size: 16px;
}

.support-link {
	color: #252422;
	text-decoration: underline;
}

.success-text {
	max-width: 300px;
	margin: auto;
}

.large_green.success-logo {
	margin-bottom: 18px;
}

.started-icon-text-group {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
	margin-bottom: -10px;
	max-width: 300px;
}

.started-icon-text-group > .retry-bold {
	text-align: left;
	font-weight: bold;
	margin: 0 0 0 16px;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.notification-links {
	background-color: var(--primary-color) !important;
	border: none;
	color: white;
	padding: 10px 40px 10px 40px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 4px 2px;
	cursor: pointer;
	margin-top: 10px;
	margin-bottom: 20px;
	box-shadow: 0px 1px 4px #888, 0px 1px 1px #888 !important;
}

.no-notification-link {
	background-color: white !important;
	border: none;
	color: black !important;
	padding: 10px 40px 10px 40px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 4px 2px;
	cursor: default;
	margin-top: 10px;
	margin-bottom: 20px;
}

.notification-flag {
	background-color: red;
	width: 12px;
	height: 12px;
	border-radius: 6px;
	position: absolute;
	top: 22px;
	left: 25px;
	border: 2px solid black;
}

.notification-flag:before {
	content: "";
	position: relative;
	display: block;
	width: 300%;
	height: 300%;
	box-sizing: border-box;
	margin-left: -100%;
	margin-top: -100%;
	border-radius: 45px;
	background-color: black;
	-webkit-animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
	animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}
  
  .notification-flag:after {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	display: block;
	width: 100%;
	height: 100%;
	background-color: red;
	border-radius: 15px;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
	-webkit-animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
	animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

.hover-buttons {
	margin: 10%;
	text-align: center;
}

.btn-hover {
	width: 200px;
	font-size: 16px;
	font-weight: 600;
	color: #fff;
	cursor: pointer;
	margin: 20px;
	height: 55px;
	text-align:center;
	border: none;
	background-size: 300% 100%;

	border-radius: 50px;
	-o-transition: all .4s ease-in-out;
	-webkit-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}

.btn-hover:hover {
	background-position: 100% 0;
	-o-transition: all .4s ease-in-out;
	-webkit-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}

.btn-hover:focus {
	outline: none;
}

.btn-hover.color-9 {
	background-image: linear-gradient(to right, #25aae1, #4481eb, #04befe, #3f86ed);
}

.btn-hover.color-9:active {
box-shadow: 0px 5px 6px rgba(0,0,0,0.3);
-o-transition: all 0s ease-in-out;
	-webkit-transition: all 0s ease-in-out;
	transition: all 0s ease-in-out;
}

.content-loans {
display: flex;
}

.multiple-loans-text {
color: var(--primary-color);
font-weight: bold;
margin-top: -50px;
}

.multiple-loans-container {
display: flex;
color: #FFFFFF;
justify-content: flex-start;
gap: 20px;
margin-top: 30px;
max-width: 680px !important;
border-radius: 4px;
flex-wrap: nowrap;
overflow-x: overlay !important;
	position: relative !important;
}

.loan-element {
padding: 15px;
width: 230px;
background: var(--primary-color);
border-radius: 4px;
box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
margin-bottom: 1rem;
z-index: 100;
flex: 0 0 auto;
}

.loan-element:hover {
background: var(--primary-color-hover);
cursor: pointer;
}

.loan-number {
font-weight: bold;
margin: 0;
font-size: 18px;
}

.loan-status {
background: #DDE9FF40;
border-radius: 8px;
padding: 0 10px;
margin: 10px auto !important;
display: inline-block;
}

.loan-create-date {
font-weight: bold;
font-size: 12px;
}

.loan-arrow-container {
padding: 0px 10px;
justify-content: end;
display: flex;
font-size: 20px;
}

.footer-multiple-loans {
background-color: var(--primary-color);
	height: 130px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #FFFFFF;
	font-weight: 600;
	bottom: 0;
	position: fixed;
}

.multiple-loans-modal {
text-align: center;
padding: 20px !important;
}

.loans-modal-body {
padding: 20px !important;
position: relative;
}

.icon-cancel-loans-modal {
position: absolute;
top: 20px;
right: 20px;
font-size: 18px;
color: #C2C9D1;
cursor: pointer;
}

.loan-title-text {
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 30px;
}

.loans-modal-container {
width: 100%;
padding: 10px;
height: 100%;
max-height: 300px;
min-height: 200px;
overflow: overlay !important;
position: relative !important;
}

.loan-option-element {
display: flex;
background: #C2C9D11A;
padding: 5px 15px 5px 20px;
border-radius: 4px;
cursor: pointer;
}

.info-loan-option-element {
margin-left: 20px;
}

.content-icon-option {
display: flex;
justify-content: center;
align-items: center;
height: auto;
}

.icon-loan-option {
color: var(--primary-color);
font-size: 18px;
}

.icon-loan-option-hidden {
font-size: 18px;
color: #FFFFFF;
}

.loan-number-option {
font-weight: bold;
}

.simple-loan-option-text {
font-weight: normal;
}

.multiple-loans-modal-button {
display: block;
margin: 10px auto 15px;
padding: 10px 20px;
color: #FFFFFF;
background: var(--primary-color);
text-align: center;
font-weight: bold;
border: 0;
outline: none;
border-radius: 4px;
width: 100%;
max-width: 200px;
}

.multiple-loans-modal-button:disabled {
opacity: 0.5;
cursor: default;
}

.multiple-loans-modal-button:hover {
font-weight: bolder;
}

@-webkit-keyframes pulse-ring {
	0% {
	  transform: scale(0.33);
	}
	80%, 100% {
	  opacity: 0;
	}
  }
   
  @keyframes pulse-ring {
	0% {
	  transform: scale(0.33);
	}
	80%, 100% {
	  opacity: 0;
	}
  }
  @-webkit-keyframes pulse-dot {
	0% {
	  transform: scale(0.8);
	}
	50% {
	  transform: scale(1);
	}
	100% {
	  transform: scale(0.8);
	}
  }
  @keyframes pulse-dot {
	0% {
	  transform: scale(0.8);
	}
	50% {
	  transform: scale(1);
	}
	100% {
	  transform: scale(0.8);
	}
  }

/* .dropdown-menu .dropdown-item {
	color: #ffffff;
} */

/* media queries */

@media only screen and (min-width: 1115px) {
	.rateMonthly > .funded-format {
		height: 105px !important;
	}

	.rateMonthly > .funded-format > .monthly-payment,
	.rateMonthly > .funded-format > .interest-rate {
		z-index: 2;
		margin-top: -7px;
	}

	.rateMonthly > .funded-format > .xl__ {
		z-index: 2;
	}

	.rateMonthly > .funded-format > div > i {
		top: 40px;
	}
}

@media only screen and (max-width: 1115px) {
	.loanData {
		flex-direction: column;
	}
	.skinnyBox {
		width: 100% !important;
	}
	.setSizeM {
		width: 50%;
		max-width: 50%;
		min-width: 50%;
	}
}

@media screen and (max-width: 768px) {
	.flex-right {
		flex-direction: column;
	}
	.setSizeW,
	.setSizeM {
		width: 100%;
		min-width: 100%;
		max-width: 100%;
	}
}

@media only screen and (max-width: 600px) {
	.loanDataHolder,
	.rate,
	.monthly {
		width: 100%;
	}
	.rateMonthly {
		flex-direction: column;
	}

	.size_M {
		flex-direction: column;
	}

	.chart {
		display: none;
		/* background-color: red; */
	}
}

.adverse_body {
	font-size: 15px;
  font-weight: 400;
	line-height: 22px;
	color: #000000;
}



.adverse_header {
	font-weight: 600;
	font-size: 25px;
	line-height: 38px;
	color: #5C2D91;
}

.email_link {
	color: #5C2D91 !important;
	text-decoration: none !important;
}

.error_screen_container {
	height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.error_screen_bar {
	background: var(--primary-color);
	max-height: 63px;
	height: 100%;
	width: 100%;
    position: fixed;
    top: 0;
}

.error_screen_logo_container {
	height: 100%;
	display: flex;
	align-items: center;
	padding: 0px 25px;
	padding-top: 5px;
}

.logo_error_screen {
	width: 153px;
	height: 100%;
}

.error_screen_content {
	display: block;
	margin: auto;
	text-align: center;
}

.error_screen_title {
	font-size: 20px;
    color: var(--primary-color);
    font-weight: 700;
    font-family: Poppins, sans-serif;
  }

.error_screen_body {
	text-align: center;
	margin: 30px auto;
	font-family: Poppins, sans-serif;
	font-weight: 600;
}

.error_screen_email {
	color: var(--primary-color) !important;
	text-decoration: none !important;
}

.error_screen_button {
	width: 90%;
	margin: auto;
	display: block;
	border-radius: 4px !important;
	border: 1px solid #5C2D918C;
	color: var(--primary-color) !important;
	background: transparent;
	text-align: center;
	cursor: pointer;
	padding-top: 5px;
	padding-bottom: 5px;
	font-family: Poppins, sans-serif;
	font-weight: 600;
	text-decoration: none !important;
}

.error_screen_button:hover {
    box-shadow: 2px 3px 16px #86b5dc,-1px 0 11px #86b5dc!important;
	border: 1 px solid var(--primary-color) !important;
}

.content.adverse-view {
	width:100%;
	max-width: 740px;
	margin:auto;
	margin-top: 5rem;
	padding-top:5rem;
	text-align: center;
	padding-left: 25px;
    padding-right: 25px;
	padding-bottom: 75px;
}

.loandepotlink {
	color: var(--primary-color);
	text-decoration: none;
}

.social_media_container {
	align-items: center;
	bottom: 0;
	display: flex;
	height: 75px;
	justify-content: flex-start;
	left: 0;
	padding-left: 100px;
	position: fixed;
	text-decoration: none;
	width: 100%;	
}

.icon_container {
	align-items: center;
	border-radius: 4px;
	background: #5C2D91;
	color: #FFFFFF;
	display: flex;
	height: 35px;
	justify-content: center;
	margin-right: 20px;
	width: 35px;
}

.icon_link {
	font-size: 18px;
}

.icon_container:hover {
	background-color: #053D60 !important;
	box-shadow: 2px 3px 16px var(--secondary-color-shadow), -1px 0px 11px var(--secondary-color-shadow) !important;
}

.codemodal-exit-icon {
	position: absolute;
	right: 30px;
	top: 5px;
	color: var(--primary-color);
	font-size: 30px;
	cursor: pointer;
}

@media (max-width: 576px) {
	.error_screen_logo_container {
		justify-content: center !important;
	}

	.content.adverse-view {
		margin-top: 1rem;
	}

	.social_media_container {
		justify-content: center !important;
		padding-left: 10px !important;
		padding-right: 10px !important;
	}

	.icon_container {
		margin: auto 10px !important;
	}
}
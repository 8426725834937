.borrower-portal-chat {
    position: fixed;
    bottom: 0px;
    right: 0px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 350px;
    z-index: 2;

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    .borrower-portal-chat-container {
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        @media screen and (max-width: 768px) {
            width: 100%;
        }

        .chat-globe {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            width: 55px;
            height: 55px;
            box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.25);
            color: --primary-color;
            margin-bottom: 3px;
            position: relative;

            .chat-notification {
                position: absolute;
                top: 0;
                right: 0;
                color: #FFFFFF;
                background-color: #FF0000;
                height: 20px;
                width: 20px;
                text-align: center;
                font-weight: 600;
                border-radius: 50%;
            }
        }

        .chat-container {
            position: relative;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
            border-radius: 11px;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 450px;
            width: 100%;
            background-color: white;
            padding: 0;

            .chat-title {
                border-radius: 11px 11px 0px 0px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: white;
                width: 100%;
                height: 120px;
                padding: 10px 0px;
                background: linear-gradient(204.01deg, #5140e9 15.41%, rgba(92, 45, 145, 0) 163.58%);

                .chat-close {
                    width: 20px;
                    height: 20px;
                    margin: 0;
                    position: absolute;
                    right: 11px;
                    top: 6px;
                    cursor: pointer;
                    font-size: 20px;
                    color: rgba(255, 255, 255, 0.7);
                }

                .chat-title-content {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                }

                h1 {
                    font-size: 14px;
                    text-align: center;
                    line-height: 1px;
                    font-weight: bold;
                }
                p {
                    text-align: center;
                    font-size: 12px;
                    line-height: 1px;
                }
            }

            .chat-tabs {
                display: flex;
                background: #053D60;
                color: white;
                width: 100%;
                height: 150px;
                margin-top: -20px;
                border-radius: 11px 11px 0px 0px;

                .chat-tab {
                    display: flex;
                    justify-content: center;
                    width: 100%;

                    .chat-refresh {
                        margin: 0px 5px;
                        cursor: pointer;
                        &:hover {
                            background-color: --primary-color;
                        }
                    }

                    .tab {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 11px;
                        width: 90%;
                        height: 30px;
                        font-size: 13px;
                        margin-top: 5px;
                        cursor: pointer;
                        &.active, &:hover {
                            background: rgba(255, 255, 255, 0.39);
                        }
                    }
                }
            }

            .chat-messages-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                border-radius: 0px 0px 11px 11px;
                position: relative;
                width: 100%;
                height: 200px;
                background: white;
                ::-webkit-scrollbar {
                    width: 0px;
                    border-radius: 11px;
                }
                ::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }

                .chat-messages {
                    border-radius: 11px;
                    background: white;
                    width: 95%;
                    height: 240px;
                    z-index: 2;
                    //border: 1px solid black;
                    margin-top: -110px;
                    padding: 2px;
                    overflow-x: hidden;
                    background: #F8F9FB;
                    overflow-y: auto;

                    .empty-chat-message {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-items: center;
                        align-items: center;
                        padding: 10px;

                        b {
                            font-size: 15px;
                        }
                        p {
                            text-align: center;
                            font-size: 13px;
                            color: #5E6572;
                        }
                    }

                    ul {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                        padding: 5px;
                    }

                    li {
                        list-style: none;
                        width: 100%;
                        min-height: 50px;
                        display: flex;
                        align-items: center;
                        margin-bottom: 5px;

                        .message-bubble {
                            max-width: 90%;
                            display: flex;
                            flex-direction: column;
                        }

                        .message-date {
                            font-size: 10px;
                            width: 100%;
                        }

                        .message {
                            border-radius: 11px;
                            padding: 8px;
                        }

                        &.consumer {
                            justify-content: flex-end;
                            .message-date {
                                text-align: right;
                            }
                            .message {
                                background: #5D2E92;
                                color: white;
                            }
                        }
                        &.system {
                            .message {
                                text-align: center;
                                font-size: 12px;
                                font-style: italic;
                                justify-content: center;
                            }
                        }
                        &.lender {
                            .message {
                                background: #EDF1F4;
                                justify-content: flex-start;
                            }
                        }
                        &.member-item {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            .member-avatar {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 42px;
                                height: 42px;
                                background: #5A6271;
                                border-radius: 25px;
                                color: #FFFFFF;
                                font-weight: 600;
                                font-size: 16px;
                            }
                            .member-info {
                                padding: 5px;
                                width: 90%;
                                height: 100%;
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: center;
                                p {
                                    line-height: 2px;
                                }
                            }
                        }
                    }
                }

                .chat-input {
                    width: 90%;
                    display: flex;
                    background: #FFFFFF;
                    border: 1px solid #DDE1E8;
                    border-radius: 100px;
                    padding: 3px;
                    margin: 10px 0px;

                    input {
                        width: 100%;
                        height: 32px;
                        border: none;
                        border-radius: 100px;
                        padding: 0px 5px;
                        outline: none;
                    }
                    button {
                        width: 35px;
                        height: 32px;
                        background: #5140e9;
                        border-radius: 25px;
                        cursor: pointer;
                        color: white;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: none;
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
}